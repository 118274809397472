.icon-angle-down {
	width: 14px;
	height: 8px;
}

.icon-angle-down-2 {
	width: 11.27px;
	height: 6.5px;
}

.icon-angle-down-3 {
	width: 10px;
	height: 5.71px;
}

.icon-arrow-left {
	width: 16.5px;
	height: 11.05px;
}

.icon-arrow-right {
	width: 16.5px;
	height: 11.05px;
}

.icon-article {
	width: 11.25px;
	height: 11.25px;
}

.icon-aws-w {
	width: 31px;
	height: 18.54px;
}

.icon-azure-w {
	width: 27px;
	height: 26px;
}

.icon-blog {
	width: 14px;
	height: 14px;
}

.icon-business-size {
	width: 25px;
	height: 25px;
}

.icon-check {
	width: 8.5px;
	height: 7.28px;
}

.icon-company {
	width: 24px;
	height: 16px;
}

.icon-cross {
	width: 28px;
	height: 28px;
}

.icon-customer {
	width: 50px;
	height: 50px;
}

.icon-customer-stories {
	width: 25px;
	height: 25px;
}

.icon-customer-story {
	width: 16px;
	height: 15px;
}

.icon-devops-hours {
	width: 50px;
	height: 51px;
}

.icon-devops-users {
	width: 71px;
	height: 47px;
}

.icon-diff-adaptive-update {
	width: 54px;
	height: 54px;
}

.icon-diff-alert-check {
	width: 54px;
	height: 54px;
}

.icon-diff-alert-star {
	width: 54px;
	height: 54px;
}

.icon-diff-angle-up {
	width: 54px;
	height: 54px;
}

.icon-diff-angles-right {
	width: 54px;
	height: 54px;
}

.icon-diff-audit {
	width: 54px;
	height: 54px;
}

.icon-diff-audit-file {
	width: 54px;
	height: 54px;
}

.icon-diff-automation {
	width: 46px;
	height: 46px;
}

.icon-diff-book {
	width: 54px;
	height: 54px;
}

.icon-diff-bookmark-check {
	width: 54px;
	height: 54px;
}

.icon-diff-bookmark-learn {
	width: 54px;
	height: 54px;
}

.icon-diff-bookmark-lock {
	width: 54px;
	height: 54px;
}

.icon-diff-bookmark-star {
	width: 51px;
	height: 54px;
}

.icon-diff-bookmark-tool {
	width: 54px;
	height: 54px;
}

.icon-diff-bookmark-update {
	width: 54px;
	height: 54px;
}

.icon-diff-bookmark-user {
	width: 54px;
	height: 54px;
}

.icon-diff-bookmarks {
	width: 54px;
	height: 54px;
}

.icon-diff-bookmarks-2 {
	width: 54px;
	height: 54px;
}

.icon-diff-box-security {
	width: 54px;
	height: 54px;
}

.icon-diff-calendar-arrow {
	width: 54px;
	height: 54px;
}

.icon-diff-calendar-events {
	width: 54px;
	height: 54px;
}

.icon-diff-calendar-update {
	width: 54px;
	height: 54px;
}

.icon-diff-chart-bar {
	width: 54px;
	height: 54px;
}

.icon-diff-chat-plus {
	width: 54px;
	height: 54px;
}

.icon-diff-check-network {
	width: 54px;
	height: 54px;
}

.icon-diff-cloud {
	width: 54px;
	height: 54px;
}

.icon-diff-cloud-check {
	width: 54px;
	height: 54px;
}

.icon-diff-cloud-tool {
	width: 54px;
	height: 54px;
}

.icon-diff-code {
	width: 54px;
	height: 54px;
}

.icon-diff-compliance-process {
	width: 54px;
	height: 54px;
}

.icon-diff-computer-phone {
	width: 50.58px;
	height: 42.15px;
}

.icon-diff-container {
	width: 54px;
	height: 54px;
}

.icon-diff-container-code {
	width: 54px;
	height: 54px;
}

.icon-diff-continuous {
	width: 54px;
	height: 54px;
}

.icon-diff-credibility {
	width: 54px;
	height: 54px;
}

.icon-diff-data-security {
	width: 54px;
	height: 54px;
}

.icon-diff-database-backup {
	width: 54px;
	height: 54px;
}

.icon-diff-database-eye {
	width: 54px;
	height: 54px;
}

.icon-diff-database-in {
	width: 54px;
	height: 54px;
}

.icon-diff-database-lock {
	width: 54px;
	height: 54px;
}

.icon-diff-database-update {
	width: 54px;
	height: 54px;
}

.icon-diff-dev-code {
	width: 54px;
	height: 54px;
}

.icon-diff-dev-doc {
	width: 54px;
	height: 54px;
}

.icon-diff-doc-check {
	width: 54px;
	height: 54px;
}

.icon-diff-expand {
	width: 54px;
	height: 54px;
}

.icon-diff-expert-support {
	width: 54px;
	height: 54px;
}

.icon-diff-eye-lock {
	width: 54px;
	height: 54px;
}

.icon-diff-faq {
	width: 54px;
	height: 54px;
}

.icon-diff-feature {
	width: 54px;
	height: 54px;
}

.icon-diff-file-cloud {
	width: 54px;
	height: 54px;
}

.icon-diff-file-edit {
	width: 54px;
	height: 54px;
}

.icon-diff-file-graph {
	width: 54px;
	height: 54px;
}

.icon-diff-file-stream {
	width: 54px;
	height: 54px;
}

.icon-diff-folder-update {
	width: 54px;
	height: 54px;
}

.icon-diff-graph {
	width: 54px;
	height: 54px;
}

.icon-diff-hands {
	width: 54px;
	height: 54px;
}

.icon-diff-iac {
	width: 42px;
	height: 42px;
}

.icon-diff-infrustructure {
	width: 78px;
	height: 78px;
}

.icon-diff-integration {
	width: 54px;
	height: 54px;
}

.icon-diff-integration-arrows {
	width: 54px;
	height: 54px;
}

.icon-diff-interactive-demo {
	width: 54px;
	height: 54px;
}

.icon-diff-kubernetes {
	width: 48px;
	height: 46px;
}

.icon-diff-learn {
	width: 54px;
	height: 54px;
}

.icon-diff-learn-connect {
	width: 50px;
	height: 50px;
}

.icon-diff-lock {
	width: 54px;
	height: 54px;
}

.icon-diff-lock-check {
	width: 54px;
	height: 54px;
}

.icon-diff-low-code {
	width: 54px;
	height: 54px;
}

.icon-diff-manage-user {
	width: 54px;
	height: 54px;
}

.icon-diff-marketing-init {
	width: 54px;
	height: 54px;
}

.icon-diff-money {
	width: 54px;
	height: 54px;
}

.icon-diff-monitoring {
	width: 50px;
	height: 39px;
}

.icon-diff-open {
	width: 41px;
	height: 36px;
}

.icon-diff-people {
	width: 54px;
	height: 54px;
}

.icon-diff-person-graph {
	width: 54px;
	height: 54px;
}

.icon-diff-platforms-plus {
	width: 54px;
	height: 54px;
}

.icon-diff-portal {
	width: 54px;
	height: 54px;
}

.icon-diff-press {
	width: 54px;
	height: 54px;
}

.icon-diff-robust-security {
	width: 54px;
	height: 54px;
}

.icon-diff-rocket {
	width: 54px;
	height: 54px;
}

.icon-diff-role-access {
	width: 54px;
	height: 54px;
}

.icon-diff-security {
	width: 54px;
	height: 54px;
}

.icon-diff-security-network {
	width: 54px;
	height: 54px;
}

.icon-diff-security-search {
	width: 42px;
	height: 50px;
}

.icon-diff-security-sq {
	width: 54px;
	height: 54px;
}

.icon-diff-security-sync {
	width: 54px;
	height: 54px;
}

.icon-diff-security-tool {
	width: 50px;
	height: 54px;
}

.icon-diff-security-user {
	width: 54px;
	height: 54px;
}

.icon-diff-settings {
	width: 54px;
	height: 54px;
}

.icon-diff-squares-lock {
	width: 54px;
	height: 54px;
}

.icon-diff-standards {
	width: 52px;
	height: 32px;
}

.icon-diff-star {
	width: 54px;
	height: 54px;
}

.icon-diff-strandardized {
	width: 54px;
	height: 54px;
}

.icon-diff-support {
	width: 54px;
	height: 54px;
}

.icon-diff-time-fast {
	width: 54px;
	height: 54px;
}

.icon-diff-timeline {
	width: 60px;
	height: 58px;
}

.icon-diff-timer {
	width: 54px;
	height: 54px;
}

.icon-diff-timer-update {
	width: 54px;
	height: 54px;
}

.icon-diff-training {
	width: 45px;
	height: 45px;
}

.icon-diff-transition {
	width: 54px;
	height: 54px;
}

.icon-diff-triangles-right {
	width: 56px;
	height: 56px;
}

.icon-diff-turn-arrow {
	width: 54px;
	height: 54px;
}

.icon-diff-update-round {
	width: 54px;
	height: 54px;
}

.icon-diff-user-doc {
	width: 54px;
	height: 54px;
}

.icon-diff-user-dots {
	width: 54px;
	height: 54px;
}

.icon-diff-workflows {
	width: 54px;
	height: 54px;
}

.icon-envelope-round {
	width: 16.43px;
	height: 16.43px;
}

.icon-facebook-round {
	width: 17.4px;
	height: 17.4px;
}

.icon-features {
	width: 20px;
	height: 19px;
}

.icon-gcp-w {
	width: 33px;
	height: 26px;
}

.icon-gradient {
	width: 207.81px;
	height: 127.25px;
}

.icon-instagram {
	width: 14px;
	height: 14px;
}

.icon-learn-connect {
	width: 25px;
	height: 25px;
}

.icon-link {
	width: 32px;
	height: 32px;
}

.icon-linkedin {
	width: 12px;
	height: 12px;
}

.icon-menu {
	width: 36px;
	height: 26px;
}

.icon-minus {
	width: 9.58px;
	height: 1.85px;
}

.icon-peace-of-mind {
	width: 57px;
	height: 50px;
}

.icon-platforms {
	width: 25px;
	height: 25px;
}

.icon-play {
	width: 47px;
	height: 47px;
}

.icon-plus {
	width: 12.24px;
	height: 12.24px;
}

.icon-premises-w {
	width: 31px;
	height: 22px;
}

.icon-price-check {
	width: 19px;
	height: 16px;
}

.icon-price-check-2 {
	width: 19px;
	height: 16px;
}

.icon-product-help {
	width: 25px;
	height: 25px;
}

.icon-resource-learn {
	width: 16px;
	height: 16px;
}

.icon-search {
	width: 16px;
	height: 16px;
}

.icon-solutions {
	width: 25px;
	height: 25px;
}

.icon-stars {
	width: 20px;
	height: 20px;
}

.icon-teams {
	width: 24px;
	height: 25px;
}

.icon-testimonial-line {
	width: 51.5px;
	height: 12.5px;
}

.icon-twitter {
	width: 17px;
	height: 13px;
}

.icon-webinar {
	width: 12.5px;
	height: 10px;
}

.icon-world {
	width: 25px;
	height: 25px;
}

.icon-x {
	width: 14.58px;
	height: 13.5px;
}

.icon-youtube {
	width: 17.99px;
	height: 13.44px;
}

